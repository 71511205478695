<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import LoadingSpinner from '@/components/Loading';
import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";
// import StateOption from "@/components/forms/state-option";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
// import CreateForm from "@/components/forms/create-form";

/**
 * Orders Component
 */
 export default {
    components: {
        Layout,
        PageHeader,
        MessageModal,
        InputGroup,
        SelectGroup,
        // SaveButton,
        // CancelButton,
        // StateOption,
        // LoadingSpinner
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `${modeName} CTC`,
            modeName,
            items: [
                {
                    text: "CTC"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                quantity: '',
                status: 1,
                shipping_name: '',
                shipping_phone: '',
                shipping_address_line_1: '',
                shipping_address_line_2: '',
                shipping_postal_code: '',
                shipping_city: '',
                shipping_state_id: '',
                shipping_country_id: '',
                courier_company: '',
                shipping_tracking_no: '',
            },
            stateOptions: [],
            countryOptions: [],
            statusOptions: [
                { name: "Payment Processing", value: "payment_processing" },    
                { name: "Pending", value: "pending" },
                { name: "Delivered", value: "delivered" },
            ],
            errors: {},
        };
    },
    async mounted() {
        this.retrieveStates().then((states) => {
            this.stateOptions = states.map((state) => ({
                name: state.name,
                value: state.id,
            }));
        });

        this.retrieveCountries().then((countries) => {
            this.countryOptions = countries.map((country) => ({
                name: country.name,
                value: country.id,
            }));
        });

        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/ctc/${this.id}`, {
                headers: authHeader(),
            });
            if (result && result.data['code'] === 'success') {
                const data = result.data['data'];
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }
                }

                this.data.quantity = data.ctc.ctc_quantity;
                this.data.status = data.ctc.status;
            }
        }
    },
    methods: {
        async retrieveStates() {
            this.loading = true;
            let states = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/state`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    states = result['data'];
                    console.log('this.stateOptions', this.stateOptions)
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return states;
        },

        async retrieveCountries() {
            this.loading = true;
            let countries = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/country`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    countries = result['data'];
                    console.log('this.countryOptions', this.countryOptions)
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return countries;
        },

        async save() {
            this.errors = {};
            let result;

            const requestData = this.data;
            result = await axios.put(`${process.env.VUE_APP_APIURL}/ctc/update-ctc/${this.id}`, requestData, {
                headers: authHeader(),
            });

            if (result) {
                if (result.data['code'] === 'success') {
                    this.$refs.messageModal.showModal('Your record has been updated successfully');
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                }
            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 15px;">
                    <div class="card-body p-4">
                        <div class="row mt-2">
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="quantity" displayName="CTC Quantity" v-model="data.quantity" :error="errors.quantity" :readOnly="true" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup id="shipping_name" displayName="Recipient's Name" v-model="data.shipping_name" :error="errors.shipping_name" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup id="shipping_phone" displayName="Phone" v-model="data.shipping_phone" :error="errors.shipping_phone" />
                            </div>

                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="shipping_address_line_1" displayName="Address Line 1" v-model="data.shipping_address_line_1" :error="errors.shipping_address_line_1" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup id="shipping_address_line_2" displayName="Address Line 2" v-model="data.shipping_address_line_2" :error="errors.shipping_address_line_2" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup id="shipping_postal_code" displayName="Postal Code" v-model="data.shipping_postal_code" :error="errors.shipping_postal_code" />
                            </div>

                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="shipping_city" displayName="City" v-model="data.shipping_city" :error="errors.shipping_city" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <SelectGroup id="shipping_state_id" displayName="State" v-model="data.shipping_state_id" :error="errors.shipping_state_id" :options="stateOptions" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <SelectGroup id="shipping_country_id" displayName="Country" v-model="data.shipping_country_id" :error="errors.shipping_country_id" :options="countryOptions" />
                            </div>

                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="courier_company" displayName="Courier Company" v-model="data.courier_company" :error="errors.courier_company" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="shipping_tracking_no" displayName="Shipping Tracking No." v-model="data.shipping_tracking_no" :error="errors.shipping_tracking_no" />
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12 col-md-4">
                                <SelectGroup id="status" displayName="Status" v-model="data.status" :error="errors.status" :options="statusOptions" />
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="row mt-2">
                            <div class="col-12">
								<button class="btn btn-orange" @click="save">
									<i class="mdi mdi-plus-thick"></i>
									Save
								</button>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>